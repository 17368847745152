<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M12.4884 13.4884C18.473 7.50386 28.2121 7.50386 34.1966 13.4884L37.4416 16.7312V12.1453C37.4416 11.4396 38.0151 10.866 38.7208 10.866C39.4265 10.866 40 11.4396 40 12.1453V19.8205C40 19.9015 39.9915 19.9804 39.9765 20.0572C39.9765 20.0614 39.9765 20.0636 39.9744 20.0678C39.9595 20.1446 39.936 20.2192 39.9083 20.2917C39.9062 20.296 39.9041 20.3024 39.9019 20.3066C39.8721 20.3791 39.8337 20.4495 39.7911 20.5156C39.7889 20.5198 39.7868 20.5241 39.7825 20.5284C39.6887 20.6712 39.5672 20.7927 39.4244 20.8865C39.4201 20.8887 39.4158 20.8908 39.4116 20.8951C39.3455 20.9377 39.2772 20.9739 39.2026 21.0038C39.1984 21.0059 39.192 21.0081 39.1877 21.0102C39.1152 21.0379 39.0427 21.0614 38.9638 21.0763C38.9596 21.0763 38.9553 21.0763 38.9532 21.0784C38.8764 21.0933 38.7975 21.1019 38.7165 21.1019H31.0413C30.3356 21.1019 29.762 20.5284 29.762 19.8227C29.762 19.117 30.3356 18.5434 31.0413 18.5434H35.6315L32.3887 15.3006C29.971 12.8808 26.7602 11.5504 23.3425 11.5504C19.9249 11.5504 16.7141 12.8808 14.2964 15.2964C9.3096 20.2832 9.3096 28.3998 14.2964 33.3866C16.7141 35.8043 19.9249 37.1346 23.3425 37.1346C26.7602 37.1346 29.971 35.8043 32.3887 33.3887C32.8876 32.8898 33.6978 32.8898 34.1988 33.3887C34.6977 33.8876 34.6977 34.6978 34.1988 35.1988C31.2971 38.0962 27.4424 39.6931 23.3425 39.6931C19.2427 39.6931 15.388 38.0962 12.4884 35.1966C6.50386 29.2121 6.50386 19.473 12.4884 13.4884Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-rotate-right-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
